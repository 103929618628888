import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"
import Card from "../../components/Card"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"

const ProjectsPage = () => {
  return (
    <>
      <SEO title="Projects" />
      <Breadcrumb crumbs={[["/"], ["/", "projects"]]} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card>Tayama - website</Card>
        <Link to="/projects/tayama/rysunki-techniczne">
          <Card>Tayama - rysunki techniczne</Card>
        </Link>
        <Card>Tayama - zdjęcia studyjne</Card>
        <Card>Education First - javascript developer</Card>
        <Card>Health - Eating in home healthy stuff</Card>
        <Card>Health - Gym, runing, freeletics</Card>
        <Card>Make life better - Gosia</Card>
        <Card>Make life better - wedding</Card>
        <Card>Make life better - friends</Card>
        <Card>codepride - Extraordinary Day</Card>
        <Card>codepride - codepride landing</Card>
        <Card>jakas aplikacja z uzyciem three.js (3d) - codepride</Card>
        <Card>
          zbudować upload file component -{" "}
          <a
            href="https://medium.com/better-programming/build-a-modern-customized-file-uploading-user-interface-in-react-with-plain-css-8a78bc92963a"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            --Link--{" "}
          </a>
        </Card>
        <Card>marcin.codes - my personal website and blog as a developer</Card>
        <Card>Lisiewicz Design - Lisiewicz Design landing</Card>
      </div>
    </>
  )
}

export default ProjectsPage
